/**
 * Accordion setup function.
 *
 * @param {Element} el The Accordion DOM element object.
 */
 const faqAccordion = el => {
    if (!el) {
        return;
    }

    const accordionBtn = el.querySelector('button');
    accordionBtn.addEventListener('click', toggleAccordion);

    /**
     * Function to toggle the accordion.
     *
     * @param {Element} e The clicked element.
     */
    function toggleAccordion( e ) {
        e.preventDefault();
        const container = document.querySelector( `#${e.currentTarget.getAttribute( 'aria-controls' )}` );

        if(e.currentTarget.getAttribute( 'aria-expanded' ) === 'true') {
            container.classList.remove('opened');
            e.currentTarget.setAttribute('aria-expanded', 'false');
            return;
        }

        container.classList.add( 'opened' );
        e.currentTarget.setAttribute('aria-expanded', 'true');
    }
}

export default faqAccordion;

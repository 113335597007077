/**
 * Open a modal by its Id, cheching if it has been shown before.
 * @param {String} modalId The modal id to open.
 * @param {Number} untilShownAgain The number of days until it is shown again.
 */
 export const checkFirstTimeVisit = (modalId, untilShownAgain = null) => {
    const lastShown = localStorage.getItem(`activate-card-popup-${modalId}-shown`);
    if(!lastShown || 0 === untilShownAgain) {
        openPopup(modalId);
        return;
    }

    if(checkDate(lastShown, untilShownAgain)) {
        openPopup(modalId);
    }
};

/**
 * Open a modal by its Id, setup it's close buttons.
 * @param {String} modalId The modal id to open.
 */
export const openPopup = modalId => {
    const modal = document.getElementById(modalId);
    if(!modal) {
        return;
    }

    const closePopup = event => {
        event.preventDefault();
        localStorage.setItem(`activate-card-popup-${modalId}-shown`, (new Date()).toLocaleDateString('en-US'));
        modal.classList.remove('open');
        modal.querySelectorAll('.modal-exit').forEach( exit => exit.removeEventListener('click', closePopup) );
    };

    modal.classList.remove('hidden');
    modal.classList.add('open');
    modal.querySelectorAll('.modal-exit').forEach( exit => exit.addEventListener('click', closePopup) );
};

/**
 * Check if date is in range of 'untilShown'.
 * @param {*} untilShown Days before the popup is shoen again.
 * @returns True if need to show popup based on date check.
 */
const checkDate = (lastShown, untilShown) => {
    const lastShownDate = new Date(lastShown);
    lastShownDate.setHours(0);
    lastShownDate.setMinutes(0);
    lastShownDate.setSeconds(0);
    lastShownDate.setMilliseconds(0);

    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const dayDiff = (date.getTime() - lastShownDate.getTime()) / (1000 * 60 * 60 * 24);
    return dayDiff > untilShown;
};

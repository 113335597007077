const collapseHeader = () => {
    let scroll = 0;
    const header = document.querySelector('header > nav');
    const body = document.querySelector('BODY');

    window.addEventListener('scroll', () => {
        const newScroll = window.scrollY;
        if ( newScroll >= 70 ) {
            if(newScroll > scroll) {
                body.classList.add('hide-header');
            } else {
                body.classList.remove('hide-header');
            }
        } else {
            body.classList.remove('hide-header');
        }

        scroll = newScroll;
    })
};

export default collapseHeader;
